import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";

const PostContainer = styled('article')`
  width: 48%;
  padding: 0px;
  margin: 20px 0;
  display: flex;

  .blog-link {
    width: 100%;
    background: #fff;
    box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;
      
    .blog-img-wrapper {
        height: 100%;
        min-width: 40%;
        display: none;

        .gatsby-image-wrapper {
          min-height: 100%;
          min-width: 100%;
        }

        .blog-img {
          width: 100%;
          min-height: 100%;
          min-width: 100%;
        }
    }

    @media(max-width: 768px){
      flex-direction: column;

      .blog-img-wrapper {
        width: 100%;
        display: block;
      }
    }
  }


  .blog-description {
    padding: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    .blog-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 0.75rem 0;

      p {
        margin: 0px;
      }
    }

    .blog-tag {
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.0625rem;
      border-bottom: 0.125rem solid #ebebeb;
    }

    h3 {
      font-size: 1.4rem;
      line-height: 1.4;
      font-weight: 700;
      margin: 0 0 0.5rem 0;
      text-transform: capitalize;

      &:hover {
        text-decoration: underline;
      }
    }

    .blog-descriptive {
      font-weight: 400;
      color: #777;
      font-size: 0.9375rem;
      line-height: 1.6;
      flex-grow: 1;
    }

    .blog-footer {
      border-top: 1px solid lightgrey;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 15px;

      .blog-author {
        background: linear-gradient(135deg,#e78f19 0%,#fda085 100%);
        padding: 5px 10px;
        border-radius: 4px;
        color: white;
      }

      p {
        margin: 0px;
      }
    }
  }

  &.full {
    width: 100%;
    margin-top: 100px;

    .blog-img-wrapper  {
      display: block;
    }

    .blog-link {
      width: 100%;

      h3 {
        font-size: 2rem;
      }
    }
  }

  @media(max-width: 768px){
    width: 100%;
  }
`;

const VideoPost = ({info, full}) => {

  return <PostContainer className={full && 'full'}>
        <div className="blog-link">
            {
              info.thumbnail &&
              <div className="blog-img-wrapper">
                <img src={info.thumbnail.fluid.src} className="blog-img"/>
              </div>
            }
            <div className="blog-description">
              <div className="blog-top">
                <p>{info.date}</p>
              </div>
              <Link to={`/media/${info.slug}`} key={info.id}>
                <h3>{info.title}</h3>
              </Link>
              <p className="blog-descriptive">{info.description.description}</p>
            </div>
        </div>
    </PostContainer>
};

export default VideoPost
