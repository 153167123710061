import React, {useState, useEffect} from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/SEO"
import BlogSubNav from "../components/BlogSubNav"
import Post from "../components/Post"
import VideoPost from "../components/VideoPost"
import BlogFilter from "../components/BlogFilter"
import VideoFilter from "../components/VideoFilter"

import styled from 'styled-components'

const Content = styled.div`

  .blog-content-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  h1 {
    font-size: 28px;
    font-weight: 300;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 20px 0 10px;
  }

  .blog-content-nav {
    width: 100%;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 2px;
      background-image: linear-gradient(135deg, #e78f19 0%, #fda085 100%);
    }
  }
`;

const Media = () => {
  const [activeFunnel, setActiveFunnel] = useState('blogs');
  const data = useStaticQuery(query);

  const [filteredBlogs, setFilteredBlogs] = useState(data.allContentfulBlogPost.edges);
  const [filteredVideos, setFilteredVideos] = useState(data.allContentfulMonthlyVideos.edges);

  // const allVideoTags = data.allContentfulMonthlyVideos.edges.map( item => {
  //   if(item.node.tags){
  //     let str = item.node.tags;
  //     return str.split(/[,]+/).filter(Boolean);
  //   }
  // });
 
  // const flatTagVideoArray = [].concat.apply([], allVideoTags).filter(Boolean);
  // const cleanVideoTagArray = Array.from(new Set(flatTagVideoArray.map((item)=> {return JSON.parse(`{ "text":"${item.trim()}", "active":false}`)})));

  // const allBlogTags = data.allContentfulBlogPost.edges.map( item => {
  //   if(item.node.tags !== null){
  //     let str = item.node.tags;
  //     return str.split(/[,]+/).filter(Boolean);
  //   }
  // });
 
  // const flatTagBlogArray = [].concat.apply([], allBlogTags).filter(Boolean);
  // const cleanBlogTagArray = Array.from(new Set(flatTagBlogArray.map((item)=> {return JSON.parse(`{ "text":"${item.trim()}", "active":false}`)})));

  function changeFunnel(tab) {
    setActiveFunnel(tab);
  };

  function filterVideos(tags){
    const activeTags = tags.filter(tag => tag.active && tag.text);
    if(activeTags.length > 0){
      const newList = filteredVideos.filter((video) => {
        let valid = false;
        if(video.node.tags !== null){
          activeTags.map((tag)=>{
            if(video.node.tags.includes(tag.text)){
              valid = true;
            }else{
              valid = false;
            }
          });
        }else{
          valid = false;
        }
        return valid;
      });

      setFilteredVideos(newList);
    }else{
      setFilteredVideos(data.allStrapiMonthlyVideos.edges);
    }
  };

  function filterBlogs(tags){
    const activeTags = tags.filter(tag => tag.active && tag.text);
    let currentParams = [];
    if(activeTags.length > 0){
      const newList = filteredBlogs.filter((blog) => {
        let valid = false;
        if(blog.node.tags !== null){
          activeTags.map((tag)=>{
            currentParams.push(tag.text);
            if(blog.node.tags.includes(tag.text)){
              valid = true;
            }else{
              valid = false;
            }
          });
        }else{
          valid = false;
        }

        return valid;
      });
      setFilteredBlogs(newList);
    }else{
      setFilteredBlogs(data.allContentfulBlogPost.edges);
    }
  };

  return <Layout>
    <SEO title="Media" description="Here is the media hub for Highrock Capital"/>
    <div className="section-center-narrow">
      <Content>
        <div className="section-padding blog-content-container">
          <BlogSubNav onChange={changeFunnel} activeFunnel={activeFunnel}/>
          {/* {activeFunnel === 'blogs' ? <BlogFilter onChange={filterBlogs} tags={cleanBlogTagArray}/> : <VideoFilter onChange={filterVideos} tags={cleanVideoTagArray}/>} */}
          {activeFunnel === 'blogs' ?
            (filteredBlogs || data.allContentfulBlogPost.edges).map((post, index) => (
              <Post info={post.node} key={index} full={index === 0}/>
            ))
            :
            (filteredVideos || data.allContentfulMonthlyVideos.edges).map((post, index) => (
              <VideoPost info={post.node} key={index} full={index === 0}/>
            ))
          }
        </div>
      </Content>
    </div>
  </Layout>
}

export const query = graphql`
{
  allContentfulBlogPost(sort: {fields: date, order: DESC}) {
    edges{
      node {
        slug
        description {
          description
        }
        date(formatString: "MMMM Do, YYYY")
        id
        title
        author
        image {
          fluid {
            src
          }
        }
      }
    }
  }
  allContentfulMonthlyVideos(sort: {fields: date, order: DESC}) {
    edges {
      node {
        slug
        description {
          description
        }
        date(formatString: "MMMM Do, YYYY")
        id
        title
        thumbnail {
          fluid {
            src
          }
        }
      }
    }
  }
}
`;


export default Media
