import React from "react"
import styled from 'styled-components'

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  h1 {
    padding: 10px 0px;
  }

  .blog-nav {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    a {
      padding: 15px 30px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      cursor: pointer;
    }
  }

  .nav-pills {
    display: flex;
    margin: 0px;
  }

.nav-pills .nav-link{
  background: #fff;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  color: #000;
}

.nav-pills .nav-link.active{
  text-align: center;
  background: #343436;
  box-shadow: none;
  color: #fff;
  cursor: initial;
} 

.tab-content{
  height: auto;
  background: #fff;
  color: #000;
  z-index: 1000;
  box-shadow: -10px 0px rgba(0, 0, 0, 0.4);
  padding: 30px;
  margin-bottom: 50px;
}

@media(max-width: 500px){
  flex-direction: column;

  .blog-nav {
    justify-content: center;
    margin-top: 25px;

    .nav-pills {
      width: 100%;
    }

    .nav-item {
      width: 50%;

      a.nav-link {
        border-radius: 0px;
        justify-content: center;
      }
    }
  }
}
`;

const BlogSubNav = (props) => {

    const runChange = (e) => {
      e.preventDefault();
      document.querySelectorAll('.nav-pills .nav-link').forEach(item => {
        item.classList.remove('active');
      });
      
      e.target.classList.add('active');
      props.onChange(e.target.attributes["value"].value);
    };

    return <nav className="blog-content-nav">
            <NavContainer className="nav-center section-center-narrow">
              <h1>Media</h1>
              <div className="blog-nav">
              <ul className="nav nav-pills" role="tablist">
                <li className="nav-item">
                  <a onClick={runChange} value="blogs" className="nav-link active">Blogs</a>
                </li>
                <li className="nav-item">
                  <a onClick={runChange} value="videos" className="nav-link">Videos</a>
                </li>
              </ul>
              </div>
            </NavContainer>
      </nav>
}

export default BlogSubNav
