import styled from "styled-components";

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% + 5px);
  margin-top: 25px;

  a {
    padding: 1px;
    background: linear-gradient(135deg,#e78f19 0%,#fda085 100%);
    border-radius: 5px;
    margin: 5px 5px 5px 0px; 
    cursor: pointer;
    display: flex;

    &:hover, &.active {
      box-shadow: none;
      span {
        background-color: transparent;
      }
    }

    &:hover {
      transform: scale(1.05);
      transition: 0.2s ease-in;
      z-index: 2;
    }

    &.active {
        &:hover {
            span {
                position: relative;
                &::after {
                content: "";
                position: absolute;
                height: 1px;
                width: 90%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: black;
                opacity: 0.5;
                }
            }
        }
    }

    span {
      background-color: white;
      border-radius: 5px;
      padding: 5px 10px;
    }
  }
`;
